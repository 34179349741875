/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-03-10",
    versionChannel: "nightly",
    buildDate: "2023-03-10T00:19:23.354Z",
    commitHash: "8c91c734c3056c2622f81b9f6c38c6c1ed75ff4e",
};
